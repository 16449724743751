body {
    margin: 0;
    font-family: 'Secular One', Sans-serif;
    font-weight: bolder;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: Rubik;
}

div {
    box-sizing: border-box;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.purple-background {
    background: transparent linear-gradient(268deg, #a666cf 0%, #ffffff00 100%)
        0% 0% no-repeat padding-box;
    opacity: 0.85;
}
